<template>
  <div>
    <section>
      <br />
      <BCarousel :autoplay="false">
        <BCarouselItem v-for="(video, i) in videos" :key="i">
          <div class="card">
            <div class="card-image">
              <figure class="image is-16by9">
                <video
                  ref="videos"
                  class="has-ratio"
                  width="640"
                  height="360"
                  :src="video.url"
                  :poster="video.poster"
                  preload="none"
                  @click="onClick(i)"
                ></video>
              </figure>
            </div>
            <div class="card-content">
              <div class="content">
                <p class="text-center">{{ video.title }}</p>
              </div>
            </div>
          </div>
        </BCarouselItem>
      </BCarousel>
    </section>
  </div>
</template>

<script>
export default {
  data: function() {
    return {
      isPlaying: false,
      videos: [
        {
          title: 'FIFA TV',
          url: 'https://golamateursfiles.blob.core.windows.net/amateurs-images/video1.mp4',
          poster: 'https://az755631.vo.msecnd.net/debuta/golstats-01-video.jpg',
          playing: false,
        },
        {
          title: 'Mejor proveedor de la industria del futbol mundial',
          url: 'https://golamateursfiles.blob.core.windows.net/amateurs-images/video2.mp4',
          poster: 'https://az755631.vo.msecnd.net/debuta/golstats-02-video.jpg',
          playing: false,
        },
        {
          title: 'Microsoft-GolStats',
          url: 'https://az740894.vo.msecnd.net/golstats-bets/golstats.mp4',
          poster: 'https://az755631.vo.msecnd.net/debuta/microsoft-video.jpg',
          playing: false,
        },
        {
          title: 'Entrevista GolStats',
          url: 'https://golamateursfiles.blob.core.windows.net/amateurs-images/SAP.mp4',
          poster: 'https://az755631.vo.msecnd.net/debuta/sap-video.jpg',
          playing: false,
        },
      ],
    };
  },
  methods: {
    onClick: function(index) {
      this.videos
        .filter((p, i) => p.playing == true && i != index)
        .forEach((p, i) => {
          this.$refs.videos[i].pause();
        });
      this.videos[index].playing = !this.videos[index].playing;
      this.videos[index].playing ? this.$refs.videos[index].play() : this.$refs.videos[index].pause();
    },
  },
};
</script>
